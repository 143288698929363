import {Injectable} from '@angular/core';
import {
  combineLatest,
  exhaustMap,
  filter,
  from,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil
} from "rxjs";
import {ConfigDTO, PortDTO} from "./dto";
import {PortService} from "./port.service";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Apollo, gql} from "apollo-angular";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";

export const CONFIG_FIELD = gql`
  fragment CONFIG_FIELD on ConfigDTO {
    createdAt
    id
    key
    value
    portId
  }
`;


const getAll = gql`
  ${CONFIG_FIELD}

  query GetConfigs($pageNumber: Int, $pageDim: Int, $where: String) {
    config(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...CONFIG_FIELD
    }
  }
`;

const getById = gql`
  ${CONFIG_FIELD}

  query GetConfigById($id: Int!) {
    config_by_pk(id: $id) {
      ...CONFIG_FIELD

    }
  }`;

const count = gql`
  query CountConfig($where: String) {
    config_aggregate(aggregation: { count: { field: "id", distinct: false } }, where: $where) {
      count
    }
  }`;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  SELECT_PORT_KEY = 'SELECTED_PORT'
  private _selectedPort: PortDTO;
  private _configs: Observable<ConfigDTO[]>
  configs$ = new ReplaySubject();
  availablePorts$ = new ReplaySubject();
  private _availablePorts: Observable<PortDTO[]>;

  constructor(private portService: PortService,
              private apollo: Apollo,
              private toastService: ToastrService) {
    this._configs = this.configs$.pipe(
      map(x => {
        const exp = ExpressionBuilder.getBuilder().or().isNull('portId')
        if (!!this.selectedPort?.id) {
          exp.eq('portId', this.selectedPort.id)
        }
        return exp
      }),
      switchMap(x => this.getAll(0, 10000, x).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data)
        )
      )
    );

    this._availablePorts = this.availablePorts$.pipe(
      exhaustMap(x => this.portService.getAll(0, 1000).pipe(
          catchError(e => {
            this.toastService.error("Errore durante il recupero dei dati")
            throw e
          }),
          map(x => x.data)
        )
      )
    );

    this.availablePorts$.next(null);
    this.configs$.next(null);

    let port = sessionStorage.getItem(this.SELECT_PORT_KEY)
    if (port) {
      this.selectedPort = JSON.parse(port)
    }
  }


  get availablePorts(): Observable<PortDTO[]> {
    return this._availablePorts;
  }

  get selectedPort(): PortDTO {
    return this._selectedPort;
  }

  set selectedPort(value: PortDTO) {
    this._selectedPort = value;
    if (value) {
      sessionStorage.setItem(this.SELECT_PORT_KEY, JSON.stringify(value));
      this.configs$.next(null)
    } else {
      sessionStorage.removeItem(this.SELECT_PORT_KEY);
      this.configs$.next(null)

    }
  }


  get configs(): Observable<ConfigDTO[]> {
    return this._configs;
  }

  getGlobalConfig(key: string): Observable<ConfigDTO> {
    if (!key) {
      return undefined;
    }
    return this._configs?.pipe(
      map((x: ConfigDTO[]) => {
        for(let c of x) {
          if(c.key.toLowerCase() == key.toLowerCase())
            return c
        }
        return undefined;
      })
    );
  }


  getPortConfig(key: string, portId: number): Observable<ConfigDTO> {
    if (!key) {
      return undefined;
    }
    return this._configs?.pipe(
      map((x: ConfigDTO[]) => {
        for(let c of x) {
          if(c.key.toLowerCase() == key.toLowerCase() && c.portId == portId)
            return c
        }
        return undefined;
      })
    );
  }

// ############ GRAPHQL #################


  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation, sort?: string): Observable<{
    data: ConfigDTO[],
    totalRows: number
  }> {
    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['config_aggregate']
        // @ts-ignore
        const data = x[0].data?.['config']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number): Observable<ConfigDTO> {


    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['config_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

}
